import React from 'react'
import './portfolio.css'
import IMG1 from '../../assets/map.png'
import IMG2 from '../../assets/at1.png'
import IMG3 from '../../assets/gh.png'
import IMG4 from '../../assets/lori-website.PNG'
import {SiLeaflet} from 'react-icons/si'
import {AiFillHtml5} from 'react-icons/ai'
import {FaCss3Alt} from 'react-icons/fa'
import {AiFillAndroid} from 'react-icons/ai'
import {FaJava} from 'react-icons/fa'
import {BsWordpress} from 'react-icons/bs'
import {SiCplusplus} from 'react-icons/si'
import {SiPhp} from 'react-icons/si'
import {IoLogoPython} from 'react-icons/io'
import {SiJavascript} from 'react-icons/si'

const Portfolio = () => {

  return (
    <section id='portfolio'>
      <h5>My Recent Work</h5>
      <h2>Portfolio</h2>

      <div className="container portfolio_container">

        <article className='portfolio_item'>
          <h2>Oral History Map</h2> 
          <div className="portfolio_item-cta">
              <a href="https://github.com/CassandraThalia/DHMOralHistoryMap" className='btn' target='_blank'>Github</a> 
              <a href="https://oralhistorymaps.com/DartmouthHeritageMuseum/" className='btn btn-primary' target='_blank'>Visit Project</a>   
          </div>
          <div className='portfolio_item_icon_container'>
            <SiLeaflet className='portfolio_item_icon' />
            <AiFillHtml5 className='portfolio_item_icon' />
            <FaCss3Alt className='portfolio_item_icon' />
          </div>
          <div className='portfolio_item-container'>
            <div className="portfolio_item-image">
              <img src={IMG1} alt="Oral History Map" />
            </div>
          </div>
        </article>

        <article className='portfolio_item'>
          <h2>Audio Tour App</h2> 
          <div className="portfolio_item-cta">
              <a href="https://github.com/CassandraThalia/Evergreen-House-Audio-Tour" className='btn' target='_blank'>Github</a> 
              <a href="https://drive.google.com/file/d/1eEr3w8fUxOiQAX2KCORJcio_wSmYIb5Q/view?usp=sharing" className='btn btn-primary' target='_blank'>Download APK</a>
          </div>
          <div className='portfolio_item_icon_container'>
            <AiFillAndroid className='portfolio_item_icon' />
            <FaJava className='portfolio_item_icon' />
          </div>
          <div className='portfolio_item-container'>
            <div className="portfolio_item-image">
              <img src={IMG2} alt="Audio Tour App" />
            </div>
          </div>
        </article>

        <article className='portfolio_item'>
          <h2>Author Website</h2> 
          <div className="portfolio_item-cta">
              <a href="https://www.lori-weber.com" className='btn btn-primary' target='_blank'>Visit Website</a>
          </div>
          <div className='portfolio_item_icon_container'>
            <BsWordpress className='portfolio_item_icon' />
          </div>
          <div className='portfolio_item-container'>
            <div className="portfolio_item-image">
              <img src={IMG4} alt="Lori Weber Website" />
            </div>
          </div>
        </article>

        <article className='portfolio_item'>
          <h2>Other Coding Examples</h2> 
          <div className="portfolio_item-cta">
            <a href="https://github.com/CassandraThalia" className='btn' target='_blank'>Github</a> 
          </div>
          <div className='portfolio_item_icon_container'>
            <SiCplusplus className='portfolio_item_icon' />
            <SiJavascript className='portfolio_item_icon' />
            <SiPhp className='portfolio_item_icon' />
            <IoLogoPython className='portfolio_item_icon' />
          </div>
          <div className='portfolio_item-container'>
            <div className="portfolio_item-image">
              <img src={IMG3} alt="Other Coding Examples" />
            </div>
          </div>
        </article>

      </div>
    </section>
  )
}

const OralHistoryMapRM = () => (
  <div className='portfolio_item-content'>
  <p>I have been building this project for several months now as part of my position as Virtual Content Technician for the Dartmouth Heritage Museum.
    One of the gaps in the museum's offerings was interactive digital content, so I took it upon myself to fill that gap. I am especially proud of the project because I completed it from "scratch".
    By that I mean that I digitized the tapes, edited the audio, and built the website entirely myself.
  </p>
  <h3>Digitization</h3>
  <p>
    When I began my position at the museum, one of the things I was asked to do was to try and digitize some of their large Oral History collection.
    I got this done using a standard cassette player and a free, open-source audio editing software called Audible -- 
    after some trial and error, I was able to get the computer to recognize the audio from the tape player through the microphone jack.
    Digitizing the tapes was a lengthy process because each tape needed to be played in full to be registered into the program.
    Once the audio was registered, I edited the levels as best I could -- some of the tapes were in <i>very</i> bad shape. 
    After saving the full, original interviews in a safe, backed-up location, I then identified the most interesting excerpts from the interviews and trimmed them down in the shorter, more digestible clips.
  </p>
  <h3>The Website</h3>
  <p>
    We already knew that a large portion of the tapes I was digitizing were about the Halifax Explosion of 1917, which is a topic that museum-goers around Nova Scotia just can't seem to get enough of.
    Therefore, I knew there would be an audience for this content if I could find a way to get it to the public.
    I began by simply uploading the files to a SoundCloud account and displaying them as embedded links in the museum's website, but I started to think about how we could enhance the experience.
    Because most people think of it as the "Halifax" Explosion, the affects of the disaster on Dartmouth are often overlooked.
    That got me thinking about how it might be neat if visitors/users could see on a map where the survivors who gave these Oral Histories actually were when the blast occurred.
    However, as an IT student, I didn't quite have the know-how to put it all together until we did a project for our JavaScript class where we used Leaflet.js.
    With that last piece of the puzzle, I was able to use a combination of HTML/CSS/JavaScript and Leaflet.js to build the Oral History Map website.
    I have had a very positive reaction to the website so far, but it is still in its early stages. 
    However, I hope to continue to build and grow this project, perhaps even to other institutions and subjects!
  </p>
  </div>
)

const AudioTourRM = () => (
  <div className='portfolio_item-content'>
  <p>
  Another project that I worked on for the Dartmouth Heritage Museum was an Android App Audio Tour. 
  The audio tour would help alieviate pressure on the very small (2 permanent full-time) staff to perform tours for guests during opening hours.
  With the help of the other staff, I prepared a script and recorded the audio using a simple, free, open-source audio-editing software.
  We had several meetings to discuss the best way to deliver the audio tour to visitors. It was decided at first to use QR codes which would be placed
  around the museum at the various "stops" and would be easy to make. However, I also floated the idea of creating an Android app, which we thought would
  be a great option down-the-road. 
  </p>
  <p>
  With that in mind, I launched into the challenge of creating an Android app for the very first time. What is most interesting about this 
  project to me from an analytical perspective is that I began the work without ever having used Android Studio before, but I finished it
  later in the fall after completing an entire class in Android app development. There were a couple of big revelations which I got from this exercise.
  First, it really highlighted to me how much time is saved by approaching a project with a baseline understanding of the development process.
  When I began building the app in the summer, I had to spend a lot of time researching each and every step of the code, because it was all 
  unfamiliar to me. The idea of "activities", how to switch between them, how to bundle, and how to use the recyclerview were all things which 
  took me a very long time to figure out on my own, but took a professional only one class to explain to me in a way that made it seem easy.
  However, on the other hand, I would say that the second big revelation I had is how much value there is in exploring something on your own time.
  At the beginning of the class, my classmates seemed totally flustered by Android studio, the layout, and what the various widgits were. But 
  because I had already spent a decent amount of my own time creating a ugly-but-working prototype, I was much more confident approaching the early 
  assignments. Although it was sometimes frustrating to see how easy something was which a few weeks ago had taken me many hours to get working, 
  at least by having attempted it myself, I had a context for it which helped me to apply it more easly when it came time to actually build our own apps. 
  </p>
  <p>
  The audio tour app is mostly finished, and overall I am very happy with the outcome. It still isn't live, because that will also be a new experience for me. I want to do my research 
  before launching someting on the play store, but I am confident that before the end of the year we will have the app ready for visitors to 
  download!
  </p>
  <p>
  To hear a sample of the recorded audio, visit our <a href="https://soundcloud.com/user-392462200/sets/evergreen-house-audio-tour">soundcloud</a>.
  </p>
  </div>
)

export default Portfolio